import React from 'react';
import {Category} from "../components/Category";

const Home = () => {

    return (
        <>
            <h2 className="content__title">All keyboard</h2>
                <Category categoryId={''}/>

        </>
    );
};
export default Home;
